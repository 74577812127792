<template>
  <v-form ref="filterForm" lazy-validation @submit="onSubmitHandler">
    <content-base-layout>
      <template v-slot:content>
        <div class="form-content">
          <div class="col-3">
            <label class="filter-label">Nº do pedido</label>
            <v-text-field
              v-model="form.number"
              placeholder="Digite"
              dense
              filled
              prepend-inner-icon="mdi-magnify"
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Id MarketPlace</label>
            <v-text-field
              v-model="form.marketPlaceId"
              placeholder="Digite"
              dense
              filled
              prepend-inner-icon="mdi-magnify"
            />
          </div>
          <div class="col-3">
            <label class="filter-label">Situação</label>
            <v-autocomplete
              class="data_filter_select_input"
              :no-filter="true"
              dense
              item-text="name"
              item-value="id"
              v-model="form.status"
              :items="statusOptions"
              placeholder="Todos"
            />
          </div>
          <div class="col-3">
            <label class="filter-label">PLataforma de integração</label>
            <v-autocomplete
              class="data_filter_select_input"
              :no-filter="true"
              dense
              item-text="name"
              item-value="id"
              v-model="form.platform"
              :items="integrationPlatformOptions"
              placeholder="Todos"
            />
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <filter-base-actions @onReset="onClearHandler"/>
      </template>
    </content-base-layout>
  </v-form>
</template>

<script>
import moment from 'moment';
import ContentBaseLayout from '@/components/filter-layout/ContentBaseLayout';
import FilterBaseActions from '@/components/filter-layout/FilterBaseActions';
import MaskInput from '@/components/template/form/input/MaskInput';
import { allowedStatus } from '@/views/order/enum/StatusOrder';

const FilterFields = {
  number: '',
  status: '',
  platform: '',
  marketPlaceId: '',
};

export default {
  components: {
    ContentBaseLayout,
    FilterBaseActions,
  },
  data() {
    return {
      form: { ...FilterFields },
      integrationPlatformOptions: [{
        id: 'OCC',
        name: 'OCC',
      },
        {
          id: 'ML',
          name: 'Mercado Livre',
        }],
      statusOptions: [
        {
          id: 'SUCCESS',
          name: 'Pedido integrado com sucesso',
        },
        {
          id: 'PENDING_SEND_ERP',
          name: 'Pedido não enviado/para o ERP',
        },
        {
          id: 'PENDING_SEND_ANYMARKET',
          name: 'Sub pedido não enviado para o Anymarket',
        },
        {
          id: 'SUBMITED_OCC',
          name: 'Pedido não recebido/integrado com o Kplace',
        }],
    };
  },
  methods: {
    onSubmitHandler(e) {
      e.preventDefault();
      const { filterForm } = this.$refs;
      const validated = filterForm?.validate();

      if (!validated) return;

      const formData = { ...this.form, ...this.formFormattedValues };
      this.$emit('onSubmit', formData);
    },
    onClearHandler() {
      this.form = { ...this.form, ...FilterFields };
      this.$emit('onReset', this.form);
    },
  },
};
</script>

import moment from 'moment';

export function mapperToMonitoringResponse(formData) {
  if (formData) {
    const order = formData;
    order.data.register = order.data.register.map(
      (item) => (
        { ...item, submittedDate: moment(item.submittedDate).format('DD/MM/YYYY HH:mm:ss'), synchronizedAt: moment(item.synchronizedAt).format('DD/MM/YYYY HH:mm:ss') }
      ),
    );
    return order;
  }
  return formData;
}

export default mapperToMonitoringResponse;

import { get } from '@/service/api';
import { buildUrl } from '@/utils/service-utils';
import { mapperToMonitoringResponse } from '@/service/monitoring/monitoring-mapper';

export default async function getListMonitoring(formData = {}, paginationOptions) {
  const { page, itemsPerPage } = paginationOptions;
  const params = await buildUrl(page, itemsPerPage);

  const { number, status, platform, marketPlaceId } = formData;

  const query = [
    number ? `( number = !${number} )` : '',
    status ? `( status = ${status} )` : '',
    platform ? `( platform = ${platform} )` : '',
    marketPlaceId ? `( marketPlaceId = ${marketPlaceId} )` : '',
  ]
    .filter((condition) => condition !== '')
    .join(' and ');

  const data = await get(`monitoring/orders/fetch?${params}&filter=${query}`);
  return mapperToMonitoringResponse(data);
}

<template>
  <v-row class='row-space'>
    <div class='font-label' :style='labelStyle()'>
      {{ label }}
    </div>
    <v-text-field class="readonly-label"
      :type='inputType'
      :value="value"
      solo
      disabled
    />
  </v-row>
</template>

<script>

import Vue from 'vue';

export default Vue.extend({
  name: 'ReadOnlyTextField',
  props: {
    value: { type: [String, Number, Object], default: '' },
    labelWidth: { type: String, default: '100%' },
    paddingTop: { type: String, default: '5px' },
    inputType: { type: String, default: 'text' },
    marginRight: { type: String, default: '0' },
    label: { type: String, default: '' },
    labelOnTop: { type: Boolean, default: false },
  },
  methods: {
    labelStyle() {
      return 'width: '
        .concat(this.labelWidth)
        .concat(';padding-top: ')
        .concat(this.paddingTop)
        .concat(';margin-right: ')
        .concat(this.marginRight);
    },
  },
});

</script>

<style lang="scss">
.readonly-label {
  .v-input__slot{
    box-shadow: none !important;
    background: #FFFFFF !important;
  }

  .v-text-field__slot {
    padding: 12px 10px 12px 0px;
  }
  margin-top: 5px !important;
}

</style>

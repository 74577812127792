export const statusOrder = {
  aguardando_pagamento: 'Aguardando pagamento',
  pedido_recebido: 'pedido recebido',
  pagamento_confirmado: 'Pagamento aprovado',
  reservado: 'Reservado',
  faturado: 'Nota fiscal emitida',
  em_separacao: 'Em separação',
  entrega_futura: 'entrega futura',
  retirada_disponivel: 'Retirada disponível',
  transporte: 'Em transporte',
  entrega_realizada: 'entrega realizada',
  aguardando_execucao_servico: 'aguardando execução de serviço',
  servico_executado: 'executado',
  finalizado: 'Entrega realizada',
  cancelado: 'Pedido cancelado',
  pedido_cancelado_manual: 'pedido cancelado',
  pedido_cancelado_ncredit: 'pedido cancelado',
  pedido_cancelado_pix: 'pedido cancelado',
  Finalizado: 'Entrega realizada',
  Faturado: 'Nota fiscal emitida',
  Transporte: 'Em transporte',
  'Pagamento confirmado': 'Pagamento aprovado',
  Cancelado: 'Pedido cancelado',
  'pedido recebido': 'Pedido criado - Aguardando pagamento',
};

export default statusOrder;

export const statusLabelOrder = {
  tracking_code: 'Código de rastreio',
  shipping_company: 'Transportadora',
};

export const allowedStatus = [
  {
    id: 'aguardando_pagamento',
    name: 'Aguardando pagamento',
  },
  {
    id: 'pagamento_confirmado',
    name: 'Pagamento aprovado',
  },
  {
    id: 'faturado',
    name: 'Nota fiscal emitida',
  },
  {
    id: 'em_separacao',
    name: 'Em separação',
  },
  {
    id: 'transporte',
    name: 'Em transporte',
  },
  {
    id: 'finalizado',
    name: 'Entrega realizada',
  },
  {
    id: 'cancelado',
    name: 'Pedido cancelado',
  },
  {
    id: 'retirada_disponivel',
    name: 'Retirada disponível',
  },
];

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"crud-content",attrs:{"fill-height":""}},[_c('Detail',{attrs:{"open":_vm.detailOpen,"item":_vm.orderSelected},on:{"cancel":_vm.cancel}}),_c('ListLayout',{attrs:{"title":_vm.title,"info":_vm.info,"buttonActions":_vm.selected.length > 0 ? _vm.buttonActions: []},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('filter-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('monitoring-form-filter',{on:{"onSubmit":_vm.onSubmitFilterHandler,"onReset":_vm.onResetFilterHandler}})]},proxy:true}])})]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"headers":_vm.headers,"loading":_vm.loading,"show-select":"","items":_vm.content,"options":_vm.paginationOptions,"server-items-length":_vm.totalElements,"footer-props":{
          'items-length': 100,
          'items-per-page-text': 'Linhas por página',
          'items-per-page-options': [10, 20, 50],
          pageText: '{0}-{1} de {2}',
        }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDetail(item)}}},[_vm._v("mdi-magnify-plus-outline")])],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pt-10 pb-10"},[_vm._v(" Nenhum resultado encontrado ")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }